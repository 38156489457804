import * as React from "react"
import { Link, useNavigate } from "react-router-dom"
import {
  AppBar,
  Box,
  CssBaseline,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  Toolbar,
  Typography,
  Button,
  Collapse,
  Menu,
  MenuItem,
  Divider,
  useMediaQuery,
} from "@mui/material"
import ExpandLess from "@mui/icons-material/ExpandLess"
import ExpandMore from "@mui/icons-material/ExpandMore"
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight"
import CloseIcon from "@mui/icons-material/Close"
import { useTheme } from "@mui/material/styles"

const drawerWidth = "100%"
const navItems = [
  { name: "Home", path: "/" },
  {
    name: "Videos",
    nestedList: [
      { name: "The Prologue", path: "/videos" },
      { name: "The Masquerade of Folly", path: "/bshakes" },
    ],
  },
  {
    name: "PLANS",
    nestedList: [
      { name: "Plan A", path: "/planA" },
      { name: "Plan B", path: "/sorry" },
    ],
  },
  { name: "Contact HP City Council", path: "/contact" },
  // { name: "Contact Ravinia", path: "/ravinia" },
  { name: "Contact Us", path: "/subscribe" },
]

function NavBar(props) {
  const { window } = props
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const [openDropdown, setOpenDropdown] = React.useState({})

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")) // Detect if the screen size is mobile
  const navigate = useNavigate() // Hook to programmatically navigate to a route

  // Separate anchorEl state for each menu (Videos and Plan)
  const [videosAnchorEl, setVideosAnchorEl] = React.useState(null)
  const [planAnchorEl, setPlanAnchorEl] = React.useState(null)

  const handleDropdownToggle = (item) => {
    setOpenDropdown((prevState) => ({
      ...prevState,
      [item]: !prevState[item],
    }))
  }

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState)
    setOpenDropdown(false)
  }

  // Separate handlers for Videos and Plan menu
  const handleVideosClick = (event) => {
    setVideosAnchorEl(event.currentTarget)
  }

  const handlePlanClick = (event) => {
    // If on desktop, navigate directly to /plan
    if (!isMobile) {
      navigate("/plan")
    } else {
      setPlanAnchorEl(event.currentTarget) // Show dropdown on mobile
    }
  }

  const handleVideosClose = () => {
    setVideosAnchorEl(null)
  }

  const renderNavItem = (item) => (
    <React.Fragment key={item.name}>
      <ListItem disablePadding>
        <ListItemButton
          sx={{
            textAlign: "start",
            color: "white",
            display: "flex",
            justifyContent: "space-between",
          }}
          onClick={() => item.nestedList && handleDropdownToggle(item.name)}
        >
          <Typography sx={{ fontWeight: 600, fontSize: "24px" }}>
            {item.name}
          </Typography>
          {item.nestedList ? (
            openDropdown[item.name] ? (
              <ExpandLess sx={{ transform: "scale(1.5)" }} />
            ) : (
              <ExpandMore sx={{ transform: "scale(1.5)" }} />
            )
          ) : null}
        </ListItemButton>
      </ListItem>
      {item.nestedList && (
        <Collapse in={openDropdown[item.name]} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {item.nestedList.map((nestedItem) => (
              <Link
                key={nestedItem.name}
                to={nestedItem.path}
                style={{ textDecoration: "none", color: "white" }}
                onClick={handleDrawerToggle}
              >
                <ListItemButton
                  key={nestedItem.name}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "16px",
                    paddingLeft: "24px",
                  }}
                >
                  <KeyboardArrowRightIcon sx={{ transform: "scale(0.8)" }} />
                  <Typography sx={{ fontWeight: 600, fontSize: "24px" }}>
                    {nestedItem.name}
                  </Typography>
                </ListItemButton>
              </Link>
            ))}
          </List>
        </Collapse>
      )}
    </React.Fragment>
  )

  const drawer = (
    <Box
      sx={{
        textAlign: "center",
        background: "linear-gradient(to bottom right, #84A586, black )",
        height: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          color: "white",
          paddingX: "20px",
        }}
      >
        <Typography
          color={"white"}
          sx={{ my: 2, fontSize: "2rem", fontWeight: 600, flexGrow: 1 }}
        >
          Explore
        </Typography>
        <CloseIcon onClick={() => handleDrawerToggle()} />
      </Box>
      <Divider />
      <List>
        {navItems.map((item) =>
          item.nestedList ? (
            renderNavItem(item)
          ) : (
            <Link
              key={item.name}
              to={item.path || "#"}
              style={{
                textDecoration: "none",
                color: "white",
                fontSize: "36px",
                fontWeight: 600,
              }}
              onClick={handleDrawerToggle}
            >
              {renderNavItem(item)}
            </Link>
          )
        )}
      </List>
    </Box>
  )

  const container =
    window !== undefined ? () => window().document.body : undefined

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        component="nav"
        position="sticky"
        sx={{ backgroundColor: "#2C2C2C" }}
      >
        <Toolbar>
          <Typography
            onClick={handleDrawerToggle}
            sx={{
              padding: ".25rem",
              border: "2px solid white",
              borderRadius: ".5rem",
              marginRight: ".75rem",
              display: { sm: "none" },
            }}
          >
            Menu
          </Typography>

          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            {navItems.map((item) => (
              <React.Fragment key={item.name}>
                {item.nestedList ? (
                  <React.Fragment>
                    <Button
                      sx={{
                        color: "#fff",
                        border: "1px solid white",
                        marginX: "10px",
                      }}
                      onClick={
                        item.name === "Videos"
                          ? handleVideosClick
                          : handlePlanClick
                      }
                      aria-controls={
                        item.name === "Videos"
                          ? videosAnchorEl
                            ? "videos-menu"
                            : undefined
                          : planAnchorEl
                          ? "plan-menu"
                          : undefined
                      }
                      aria-haspopup="true"
                      aria-expanded={
                        item.name === "Videos"
                          ? Boolean(videosAnchorEl)
                          : Boolean(planAnchorEl)
                      }
                    >
                      {item.name}
                    </Button>
                    {item.name === "Videos" && (
                      <Menu
                        id="videos-menu"
                        anchorEl={videosAnchorEl}
                        open={Boolean(videosAnchorEl)}
                        onClose={handleVideosClose}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                      >
                        {item.nestedList.map((nestedItem) => (
                          <MenuItem
                            onClick={handleVideosClose}
                            key={nestedItem.name}
                          >
                            <Link
                              to={nestedItem.path}
                              style={{ textDecoration: "none", color: "black" }}
                            >
                              {nestedItem.name}
                            </Link>
                          </MenuItem>
                        ))}
                      </Menu>
                    )}
                  </React.Fragment>
                ) : (
                  <Link
                    to={item.path}
                    style={{ textDecoration: "none", color: "white" }}
                    key={item.name}
                  >
                    <Button
                      sx={{
                        color: "#fff",
                        border: "1px solid white",
                        marginX: "10px",
                      }}
                    >
                      {item.name}
                    </Button>
                  </Link>
                )}
              </React.Fragment>
            ))}
          </Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              borderTopRightRadius: "0%",
              borderBottomRightRadius: "100%",
              transform: "skewY(-50deg)",
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </Box>
  )
}

export default NavBar
