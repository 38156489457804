// import logo from './logo.svg';
import { BrowserRouter, Routes, Route } from "react-router-dom"
import "./App.css"
import Home from "./pages/Home"
import Banner from "./components/Banner"
import Faqs from "./pages/Faqs"
import NavBar from "./components/NavBar"
import Subscribe from "./pages/Subscribe"
import Links from "./pages/Links"
import Footer from "./components/Footer"
import Contact from "./pages/Contact"
// import Rally from "./pages/Rally"
// import GetEmails from "./pages/GetEmails"
// import ContactRavinia from "./pages/ContactRavinia"
// import Videos from "./pages/Videos"
import Plan from "./pages/Plan"
import Prologue from "./pages/Prologue"
import Masquerade from "./pages/Masquerade"
import PlanB from "./pages/PlanB"
import Sorry from "./pages/Sorry"
import GetMerch from "./pages/GetMerch"
import PlanPage from "./pages/PlanPage"

function App() {
  return (
    <BrowserRouter>
      <Banner />
      <NavBar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/faqs" element={<Faqs />} />
        <Route path="/subscribe" element={<Subscribe />} />
        <Route path="/links" element={<Links />} />
        <Route path="/contact" element={<Contact />} />
        {/* <Route path="/ravinia" element={<ContactRavinia />} /> */}
        {/* <Route path="/rally" element={<Rally />} /> */}
        {/* <Route path="/db-stuff" element={<GetEmails />} /> */}
        {/* <Route path="/videos" element={<Videos />} /> */}
        <Route path="/videos" element={<Prologue />} />
        <Route path="/bshakes" element={<Masquerade />} />
        <Route path="/sorry" element={<Sorry />} />
        <Route path="/merch" element={<GetMerch />} />
        <Route path="/plan" element={<PlanPage />} />
        <Route path="/planA" element={<Plan />} />
        <Route path="/levisplan" element={<PlanB />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  )
}

export default App
