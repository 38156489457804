import React, { useRef, useEffect, useState } from "react"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} from "@mui/material"
import { Link } from "react-router-dom"
import planB from "../planB.json"
import ArrowBackIcon from "@mui/icons-material/ArrowBack" // Import back arrow icon
// import planB from "../planB.json"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange"
import RateTaxPlanModal from "../components/RateTaxPlanModal"
import PieChartB from "../components/PieChartB"
import PieChartBb from "../components/PieChartBb"
import RevCompsB from "../components/RevCompsB"
import RevPercentCompB from "../components/RevPercentCompB"
import BarGraphB from "../components/BarGraphB"

function createData(revenueStream, currentTax, newTax, amusement) {
  return { revenueStream, currentTax, newTax, amusement }
}

const rows = [
  createData("5% Admissions Fee (Example)", "$1,750,000", "$1,750,000", "N/A"),
  createData("Carbon Emissions Tax", "N/A", "$210,600", "$210,600"),
  createData("Naming Rights Tax", "N/A", "$350,000", "$350,000"),
  createData("12% Amusement Tax", "N/A", "N/A", "$4,200,000"),
  createData("Property Tax Equivalent", "N/A", "N/A", "$1,400,000"),
  createData("New Mayoral Salary", "N/A", "N/A", "($160,000)"),
  createData("Total Annual Revenue", "$1,750,000", "$2,310,600", "$6,000,600"),
  createData("% of City's Budget", "1.4%", "1.95%", "5.085%"),
]

const headerCell = {
  fontWeight: "bold", // Bold text
  backgroundColor: "#f5f5f5", // Light grey background
}

const PlanB = () => {
  const [open, setOpen] = useState(false)

  const tableRef = useRef(null)

  useEffect(() => {
    const isSmallScreen = window.innerWidth <= 600 // Define your mobile breakpoint
    if (isSmallScreen && tableRef.current) {
      const scrollAmount = tableRef.current.scrollWidth * 1 // Scroll to 100% of the table width
      tableRef.current.scrollLeft = scrollAmount
    }
  }, [])

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const boldifyPercentage = (text) => {
    // Split the text on every "5%" and store it in an array
    const parts = text.split(
      "adding approximately 5% to the city’s annual budget."
    )

    return (
      <>
        {parts.map((part, index) => (
          <React.Fragment key={index}>
            {part}
            {/* Add bold "5%" after every part except the last */}
            {index < parts.length - 1 && (
              <strong>
                adding approximately 5% to the city’s annual budget.
              </strong>
            )}
          </React.Fragment>
        ))}
      </>
    )
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        alignItems: "center",
        padding: "2rem",
        gap: "2rem",
      }}
    >
      {/* Floating Back Button */}
      <Link to="/plan" style={{ textDecoration: "none" }}>
        <Button
          variant="contained"
          color="secondary"
          startIcon={<ArrowBackIcon />}
          sx={{
            position: "fixed",
            top: "16px",
            left: "16px",
            zIndex: 1000, // Ensure it floats above other content
          }}
        >
          Back
        </Button>
      </Link>
      <a href="./assets/the_BEACON.pdf" download={"the_BEACON"} target="_blank">
        <Button
          endIcon={<CurrencyExchangeIcon />}
          variant="contained"
          color="success"
        >
          Download the full Plan here
        </Button>
      </a>
      {/* Button to open the modal */}
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          handleOpen()
        }}
      >
        Rate the Plan
      </Button>
      {/* adding comments */}
      <RateTaxPlanModal
        open={open}
        plan={"planB"}
        handleClose={handleClose}
        handleOpen={handleOpen}
      />
      <Box
        sx={{
          backgroundColor: "#806201",
          borderRadius: "15px",
          padding: "12px",
        }}
      >
        <Typography
          textAlign={"center"}
          fontSize={"42px"}
          sx={{
            textShadow: "1px 1px 1px rgba(0,0,0,0.5)",
            fontWeight: 900,
            textDecoration: "underline",
            paddingBottom: "20px",
          }}
          color={"white"}
        >
          The BEACON:
        </Typography>
        <Typography
          textAlign={"center"}
          fontSize={"24px"}
          sx={{
            textShadow: "1px 1px 1px rgba(0,0,0,0.5)",
            fontWeight: 600,
          }}
          color={"white"}
        >
          Budget Enhancement And Community Optimization Network
        </Typography>
      </Box>

      <Box
        sx={{
          marginTop: "20px",
          display: "flex",
          flexDirection: "column",
          gap: ".875rem",
        }}
      >
        <Box
          sx={{
            border: "2px black solid",
            borderRadius: "15px",
            padding: "1rem",
          }}
        >
          <Typography
            sx={{ fontSize: "24px", fontWeight: 600, textAlign: "center" }}
          >
            Objective:
          </Typography>
          <Typography>{boldifyPercentage(planB.objective)}</Typography>
          <Typography sx={{ marginTop: ".5rem" }}>
            {planB.objective2}
          </Typography>
        </Box>
        <Box>
          {planB.sections.map((section, index) => (
            <Accordion key={index}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: "1.5rem",

                    color: "black",
                  }}
                >
                  {section.title}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>{section.description}</AccordionDetails>
              {section.description2 && (
                <AccordionDetails>{section.description2}</AccordionDetails>
              )}
              {section.description3 && (
                <AccordionDetails>{section.description3}</AccordionDetails>
              )}
              <AccordionDetails>
                {section.calculationDescription}
              </AccordionDetails>
              <AccordionDetails>
                {section.calculation}
                <span style={{ fontWeight: 900 }}>{section.totalRevenue}</span>
              </AccordionDetails>
              {section.calculationDescription2 && (
                <>
                  <AccordionDetails>
                    {section.calculationDescription2}
                  </AccordionDetails>
                  <AccordionDetails>
                    {section.calculation2}
                    <span style={{ fontWeight: 900 }}>
                      {section.totalRevenue2}
                    </span>
                  </AccordionDetails>
                </>
              )}
              {section.closingArg && (
                <AccordionDetails>
                  <Typography
                    fontWeight={600}
                  >{`***${section.closingArg}***`}</Typography>
                </AccordionDetails>
              )}
              {index === planB.sections.length - 2 && (
                <AccordionDetails
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "2rem",
                    alignItems: "center",
                  }}
                >
                  <PieChartB /> {/* Render the Pie Chart */}
                  <PieChartBb />
                  <BarGraphB />
                </AccordionDetails>
              )}
            </Accordion>
          ))}
        </Box>
      </Box>
      <Box textAlign={"center"}>
        <Typography fontWeight={900} fontSize={24}>
          Side-by-Side Comparison of Revenue
        </Typography>
        <Typography fontSize={12}>
          **Using Ravinia Festival as ONLY an example**
        </Typography>
        <Typography fontSize={12}>
          **Based off an average of $35,000,000 in annual revenue as only an
          example**
        </Typography>
      </Box>
      <TableContainer
        component={Paper}
        sx={{
          boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.3)", // Thicker shadow
          borderRadius: "12px", // Optional: To give it rounded corners
          // padding: "1rem", // Optional: Adds padding around the table
          overflowX: "auto",
          width: { xs: "100vw", sm: "80vw" },
        }}
        ref={tableRef}
      >
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell
                sx={{ ...headerCell, position: "sticky", top: 0, left: 0 }}
              >
                Revenue Stream
              </TableCell>
              <TableCell sx={headerCell} align="right">
                Current (Before New Taxes)
              </TableCell>
              <TableCell sx={headerCell} align="right">
                New Taxes (Before Agreement Expiration)
              </TableCell>
              <TableCell sx={headerCell} align="right">
                Amusement Tax (After Agreement Expiration)
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow
                key={row.revenueStream}
                sx={{
                  "&:nth-last-child(-n+2) td": {
                    fontWeight: 900, // bold text for the last two rows
                    fontSize: "18px", // 16px font size for the last two rows
                  },
                }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  sx={{
                    fontWeight: index >= rows.length - 2 ? 900 : "inherit",
                    fontSize: index >= rows.length - 2 ? "18px" : "inherit",
                    position: "sticky",
                    left: 0,
                    backgroundColor: "white", // Add a background color to avoid transparency
                    zIndex: 1, // Ensures the header is above the other cells
                    ...headerCell,
                    ...(index >= 0 &&
                      index < rows.length - 2 && {
                        fontSize: "14px",
                        fontWeight: 400,
                      }),
                  }}
                >
                  {row.revenueStream}
                </TableCell>
                <TableCell align="right">{row.currentTax}</TableCell>
                <TableCell align="right">{row.newTax}</TableCell>
                <TableCell align="right">{row.amusement}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <RevCompsB />
      <RevPercentCompB />

      <Typography fontWeight={900} fontSize={24}>
        Conclusion
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignContent: "center",
          alignItems: "center",
          paddingX: "1.5rem",
          gap: "1rem",
        }}
      >
        <Typography>{planB.conclusion}</Typography>
        <Typography>{planB.conclusion2}</Typography>
        <Typography>{planB.conclusion3}</Typography>
      </Box>
      <Button variant="contained" color="primary" onClick={handleOpen}>
        Rate the Plan
      </Button>
      <a href="./assets/the_BEACON.pdf" download={"the_BEACON"} target="_blank">
        <Button
          endIcon={<CurrencyExchangeIcon />}
          variant="contained"
          color="success"
        >
          Download the Plan!
        </Button>
      </a>
    </Box>
  )
}

export default PlanB
