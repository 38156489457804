import React from "react"
import { Grid, Typography, useMediaQuery, useTheme, Box } from "@mui/material"

const Prologue = () => {
  const theme = useTheme()
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"))
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        alignItems: "center",
        padding: "2rem",
        gap: "2rem",
      }}
    >
      <Grid
        container
        sx={{
          justifyContent: "center",
          alignItems: "center",
          width: { md: "75%" },
        }}
      >
        <Grid item md={5}>
          <Typography>
            Here is a video from the recent Highland Park City Hall meeting
            (2/12/2024), marking the onset of much of the controversy. Notably,
            an entire minute featuring local resident Levi Kane was omitted in
            the official release. Kane has responded by sharing the complete,
            unedited version of his three-minute statement, also available for
            viewing below.
          </Typography>
        </Grid>
        <Grid item md={7}>
          <iframe
            width={isMobileScreen ? "375" : "560"}
            height={isMobileScreen ? "280" : "315"}
            src="https://www.youtube.com/embed/gd84Ak0U0IA?si=XGTDt-XoKGqLXPua&cc_load_policy=1&modestbranding=1&rel=0"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowFullScreen
          />

          <iframe
            width={isMobileScreen ? "375" : "560"}
            height={isMobileScreen ? "280" : "315"}
            src="https://www.youtube.com/embed/22TbNc6Qf80?si=13QTyPe97JAglqIO&cc_load_policy=1&modestbranding=1&rel=0"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowFullScreen
          />
        </Grid>
        <Grid item md={5}>
          <Typography>
            An additional link from April 10, 2024, reveals a significantly
            smaller yet unanimously supportive crowd advocating for the
            amendment of the law. One key provision approved during this meeting
            grants every business the right to obtain a liquor permit. However,
            this comes with a significant stipulation: business owners will be
            precluded from running for or holding any city office. Despite the
            potential implications of such a legislative change, the assembly
            opted to pass the measure without dedicating sufficient time—two
            weeks—to fully evaluate the potential advantages and disadvantages
          </Typography>
        </Grid>
        <Grid item md={7}>
          <iframe
            width={isMobileScreen ? "375" : "560"}
            height={isMobileScreen ? "280" : "315"}
            src="https://www.youtube.com/embed/U5jh2ltKcMQ?si=AjvTpeGmgMQv1L15&cc_load_policy=1&modestbranding=1&rel=0"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowFullScreen
          />
        </Grid>
        <Grid item md={5}>
          <Typography>
            The video featured below captures a poignant moment from April 24th,
            2024, where approximately 200-250 residents of Highland Park
            convened at the city hall. They united in a heartfelt appeal for the
            council to convene a special session aimed at amending the liquor
            law before the impending resignation of Jeff Hoobler on April 30th.
            This edited version of the council meeting has been carefully
            curated to highlight only those segments that pertain to
            contributions from the public, emphasizing the community's active
            engagement in civic matters.
          </Typography>
        </Grid>
        <Grid item md={7}>
          <iframe
            width={isMobileScreen ? "375" : "560"}
            height={isMobileScreen ? "280" : "315"}
            src="https://www.youtube.com/embed/ztrEOztFsvc?si=hFlmiwFB9JfBjj9t&cc_load_policy=1&modestbranding=1&rel=0"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowFullScreen
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default Prologue
